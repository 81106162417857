/*		Default Layout: 1200px and above. 
                Gutters: 24px.
                Outer margins: 48px.
                Leftover space for scrollbars @1200px: 32px.
-------------------------------------------------------------------------------
cols    1     2      3      4      5      6      7      8      9      10
px      68    160    252    344    436    528    620    712    804    896    */


.clear{
    display: none;
}

@media only screen and (min-width: 768px){
    
    


}




@media only screen and (min-width: 400px) and (max-width: 990px) {
 




}

@media only screen and (min-width: 990px) and (max-width: 1198px) {
    
}

/*		Tablet Layout: 1024px.
                Gutters: 24px.
                Outer margins: 28px.
                Inherits styles from: Desktop Layout.
-----------------------------------------------------------------
cols    1     2      3      4      5      6      7      8
px      68    160    252    344    436    528    620    712    */

@media only screen and (min-width: 992px) and (max-width: 1199px) {

   

}



/*		Tablet Layout: 768px.
                Gutters: 24px.
                Outer margins: 28px.
                Inherits styles from: Default Layout.
-----------------------------------------------------------------
cols    1     2      3      4      5      6      7      8
px      68    160    252    344    436    528    620    712    */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    
    

}


/*		Mobile Layout: 320px.
                Gutters: 24px.
                Outer margins: 34px.
                Inherits styles from: Default Layout.
---------------------------------------------
cols    1     2      3
px      68    160    252    */

@media only screen and (max-width: 767px) {
    
    

}



/*		Wide Mobile Layout: 480px.
                Gutters: 24px.
                Outer margins: 22px.
                Inherits styles from: Default Layout, Mobile Layout.
------------------------------------------------------------
cols    1     2      3      4      5
px      68    160    252    344    436    */

@media only screen and (min-width: 480px) and (max-width: 767px) {


}

@media only screen and (max-width: 568px) {

}


/*	Retina media query.
        Overrides styles for devices with a 
        device-pixel-ratio of 2+, such as iPhone 4.
-----------------------------------------------    */

@media 
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {



}